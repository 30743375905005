import {action, makeObservable, observable} from 'mobx';
import {HubConnectionState} from '@microsoft/signalr';
import {
  ConversationWithUserMetadata,
  MessageDataWithUserMetadata,
  MessageRecievedDataWithUserMetadata, PresenceUpdateDataWithUserMetadata
} from '@fosh/chat-client/FoshChatClient.Types';
import {IUser} from '../declarations/iUser';
import {FoshChatClient} from '@fosh/chat-client';
import {getUserInfo} from '../app/functions/getUserInfo';
import {convertReceivedMsgToMsgWithUser} from '../app/functions/convertReceivedMsgToMsgWithUser';
import {getOtherUserId} from '../app/functions/getOtherUserId';
import {
  ConversationDeletedData,
  MarkConversationAsReadData,
  MessageDeletedData
} from '@fosh/chat-client/hubs/ChatHub.Types';
import {getJwtToken} from '../app/functions/getToken';

class ChatStore {
  appId: string;
  chatClient: FoshChatClient<IUser>;
  
  myUserId: string;
  otherUserId: string;
  connectionState: HubConnectionState;
  
  // Conversation
  creatingConversation: boolean;
  
  // Conversations
  isConversationsLoading: boolean;
  isMoreConversationsAvailable: boolean;
  isPastConversationsLoading: boolean;
  conversations: ConversationWithUserMetadata<IUser>[];
  markingAllMessagesAsRead: boolean;
  
  // Current Conversation
  openedConversation: ConversationWithUserMetadata<IUser> | null;
  messagesOfOpenedConversation: MessageDataWithUserMetadata<IUser>[];
  isMoreMessagesAvailableInOpenedConversation: boolean;
  isMessagesLoading: boolean;
  isMoreMessagesLoading: boolean;
  
  // Messages UI
  userMessage: string;
  isSendingMessage: boolean;
  otherUserPresenceState: boolean;
  
  constructor() {
    makeObservable(this, {
      myUserId: observable,
      setMyUserId: action,
      otherUserId: observable,
      setOtherUserId: action,
      connectionState: observable,
      setConnectionState: action,
      
      creatingConversation: observable,
      setCreatingConversation: action,
  
      isConversationsLoading: observable,
      setConversationsLoading: action,
      isPastConversationsLoading: observable,
      setIsPastConversationsLoading: action,
      isMoreConversationsAvailable: observable,
      setIsMoreConversationsAvailable: action,
      conversations: observable,
      setConversations: action,
      markingAllMessagesAsRead: observable,
      setMarkingAllMessagesAsRead: action,
  
      openedConversation: observable,
      setOpenedConversation: action,
      messagesOfOpenedConversation: observable,
      setMessagesOfOpenedConversation: action,
      isMoreMessagesAvailableInOpenedConversation: observable,
      setIsMoreMessagesAvailableInOpenedConversation: action,
      isMessagesLoading: observable,
      setIsMessagesLoading: action,
      isMoreMessagesLoading: observable,
      setIsMoreMessagesLoading: action,
  
      userMessage: observable,
      setUserMessage: action,
      isSendingMessage: observable,
      setIsSendingMessage: action,
      otherUserPresenceState: observable,
      setOtherUserPresenceState: action
    });
  
    this.appId = 'advancer-id';
    this.chatClient = new FoshChatClient(this.appId, getUserInfo);
  
    this.chatClient.Events.on('connectionStateChanged', this.onConnectionStateChanged.bind(this));
    this.chatClient.Events.on('messageReceived', this.onMessageReceived.bind(this));
    this.chatClient.Events.on('presenceUpdate', this.precenseUpdated.bind(this));
    this.chatClient.Events.on('markAllMessagesAsRead', this.markAllMessagesAsRead.bind(this));
    this.chatClient.Events.on('markConversationAsRead', this.markConversationAsRead.bind(this));
    this.chatClient.Events.on('conversationDeleted', this.conversationDeleted.bind(this));
    this.chatClient.Events.on('messageDeleted', this.messageDeleted.bind(this));
    
    this.myUserId = '';
    this.otherUserId = '';
    this.connectionState = HubConnectionState.Disconnected;
    
    // Conversation initializers
    this.creatingConversation = false;
    
    // Conversations initializers
    this.isConversationsLoading = false;
    this.isPastConversationsLoading = false;
    this.conversations = [];
    this.isMoreConversationsAvailable = false;
    this.markingAllMessagesAsRead = false;
    
    // Current Conversation initializers
    this.openedConversation = null;
    this.messagesOfOpenedConversation = [];
    this.isMoreMessagesAvailableInOpenedConversation = false;
    this.isMessagesLoading = false;
    this.isMoreMessagesLoading = false;
    
    // Messages UI
    this.userMessage = '';
    this.isSendingMessage = false;
    this.otherUserPresenceState = false;
  }
  
  setMyUserId(userId: string) {
    this.myUserId = userId;
  }
  
  setConnectionState(connectionState: HubConnectionState) {
    this.connectionState = connectionState;
  }
  
  setCreatingConversation(newState: boolean) {
    this.creatingConversation = newState;
  }
  
  setConversationsLoading(newState: boolean) {
    this.isConversationsLoading = newState;
  }
  
  setConversations(newConversations: ConversationWithUserMetadata<IUser>[]) {
    this.conversations = newConversations;
  }
  
  setIsMoreConversationsAvailable(newState: boolean) {
    this.isMoreConversationsAvailable = newState;
  }
  
  setOpenedConversation(conversation: ConversationWithUserMetadata<IUser> | null) {
    this.openedConversation = conversation;
  }
  
  setMessagesOfOpenedConversation(messages: MessageDataWithUserMetadata<IUser>[]) {
    this.messagesOfOpenedConversation = messages;
  }
  
  setIsMoreMessagesAvailableInOpenedConversation(newState: boolean) {
    this.isMoreMessagesAvailableInOpenedConversation = newState;
  }
  
  setIsMessagesLoading(newState: boolean) {
    this.isMessagesLoading = newState;
  }
  
  setIsMoreMessagesLoading(newState: boolean) {
    this.isMoreMessagesLoading = newState;
  }
  
  setUserMessage(newMessage: string) {
    this.userMessage = newMessage;
  }
  
  setIsSendingMessage(newState: boolean) {
    this.isSendingMessage = newState;
  }
  
  setIsPastConversationsLoading(newState: boolean) {
    this.isPastConversationsLoading = newState;
  }
  
  setMarkingAllMessagesAsRead(newState: boolean) {
    this.markingAllMessagesAsRead = newState;
  }
  
  setOtherUserId(otherUserId: string) {
    this.otherUserId = otherUserId;
  }
  
  setOtherUserPresenceState(newState: boolean) {
    this.otherUserPresenceState = newState;
  }
  
  
  // Chat Events
  async onConnectionStateChanged(newState: HubConnectionState) {
    this.setConnectionState(newState);
    
    if (newState === HubConnectionState.Connected) {
      await this.onChatConnected();
    }
  }
  
  async onChatConnected() {
    this.setConversationsLoading(true);
  
    const conversations = await this.chatClient.getConversations(new Date());
  
    this.setConversations(conversations.conversations);
    this.setIsMoreConversationsAvailable(conversations.isMoreConversationsAvailable);
  
    this.setConversationsLoading(false);
  }
  
  async onMessageReceived(messageReceivedData: MessageRecievedDataWithUserMetadata<IUser>) {
    console.log('onMessageReceived', messageReceivedData);
  
    if (this.openedConversation?.conversationId === messageReceivedData.conversationId) {
      this.setMessagesOfOpenedConversation([
        ...this.messagesOfOpenedConversation,
        convertReceivedMsgToMsgWithUser(messageReceivedData)
      ]);
    } else {
      const newConversations = this.conversations.map(f => {
        if (f.conversationId === messageReceivedData.conversationId) {
          f.unreadMessageCount++;
        }
      
        return f;
      });
    
      this.setConversations(newConversations);
    }
  }
  
  async precenseUpdated(presenceUpdateData: PresenceUpdateDataWithUserMetadata<IUser>) {
    console.log('precenseUpdated', presenceUpdateData);
  
    if (this.openedConversation == null) {
      return;
    }
  
    if (presenceUpdateData.userId === getOtherUserId(this.myUserId, this.openedConversation.userIds)) {
      this.setOtherUserPresenceState(presenceUpdateData.isOnline);
    }
  }
  
  async markAllMessagesAsRead() {
    console.log('markAllMessagesAsRead');
  
    const newConversations = this.conversations.map(c => {
      c.unreadMessageCount = 0;
      return c;
    });
  
    this.setConversations(newConversations);
  }
  
  async markConversationAsRead(markConversationAsReadData: MarkConversationAsReadData) {
    console.log('markConversationAsRead', markConversationAsReadData);
  
    const newConversations = this.conversations.map(c => {
      if (c.conversationId === markConversationAsReadData.conversationId) {
        c.unreadMessageCount = 0;
      }
    
      return c;
    });
  
    this.setConversations(newConversations);
  }
  
  async conversationDeleted(conversationDeletedData: ConversationDeletedData) {
    console.log('conversationDeleted', conversationDeletedData);
  
    if (this.openedConversation?.conversationId === conversationDeletedData.conversationId) {
      this.setOpenedConversation(null);
    }
  
    const newConversations = this.conversations.filter(f => f.conversationId !== conversationDeletedData.conversationId);
    this.setConversations(newConversations);
  }
  
  async messageDeleted(messageDeletedData: MessageDeletedData) {
    console.log('messageDeleted', messageDeletedData);
  
    if (messageDeletedData.conversationId !== this.openedConversation?.conversationId) {
      return;
    }
  
    const newMessages = this.messagesOfOpenedConversation.filter(f => f.messageId !== messageDeletedData.messageId);
    this.setMessagesOfOpenedConversation(newMessages);
  }
  
  // Chat Methods
  removeConversationFromConversationsIfAvailable(conversationId: string) {
    this.setConversations(this.conversations.filter(f => f.conversationId !== conversationId));
  }
  
  async subscribeToPresence(otherUserId: string) {
    if (this.openedConversation != null) {
      const otherUserId = getOtherUserId(this.myUserId, this.openedConversation.userIds);
      
      if (otherUserId != null) {
        await this.chatClient.unsubscribeFromPresence([otherUserId]);
      }
    }
    
    await this.chatClient.subscribeToPresence([otherUserId]);
  }
  
  changeConnectionUrl(newUrl: string) {
    this.chatClient.setConnectionUrl(newUrl);
  }
  
  async createConversation() {
    this.setCreatingConversation(true);
    
    console.log(this.otherUserId);
    
    const conversationResult = await this.chatClient.getConversation(this.otherUserId);
    const messagesInConversation = await this.chatClient.getConversationMessages(conversationResult.conversation.conversationId, new Date());
    await this.subscribeToPresence(this.otherUserId);
    
    this.removeConversationFromConversationsIfAvailable(conversationResult.conversation.conversationId);
    this.setConversations([conversationResult.conversation, ...this.conversations]);
    
    this.setMessagesOfOpenedConversation(messagesInConversation.messages.reverse());
    this.setIsMoreMessagesAvailableInOpenedConversation(messagesInConversation.isMoreMessagesAvailable);
    this.setOpenedConversation(conversationResult.conversation);
    
    this.setCreatingConversation(false);
  };
  
  async openConversation(conversation: ConversationWithUserMetadata<IUser>) {
    this.setIsMessagesLoading(true);
    this.setOpenedConversation(conversation);
  
    const openedConversation = this.conversations.find(f => f.conversationId === conversation.conversationId);
  
    if (openedConversation != null) {
      const otherUserId = getOtherUserId(this.myUserId, openedConversation.userIds);
    
      if (otherUserId != null) {
        await this.subscribeToPresence(otherUserId);
      }
    
      this.setOpenedConversation(openedConversation);
    }
  
    const messagesInConversation = await this.chatClient.getConversationMessages(conversation.conversationId, new Date());
  
    this.setMessagesOfOpenedConversation(messagesInConversation.messages.reverse());
    this.setIsMoreMessagesAvailableInOpenedConversation(messagesInConversation.isMoreMessagesAvailable);
    this.setIsMessagesLoading(false);
  }
  
  async connectToChat() {
    if(this.myUserId !== null && this.myUserId.length > 0 ) {
      const jwt = await getJwtToken(this.myUserId);
      this.chatClient.setUserJwt(jwt);
      await this.chatClient.Connect();
    }
  }
  
  async disconnectFromChat() {
    await this.chatClient.Disconnect();
  }
  
  async loadPastConversations() {
    this.setIsPastConversationsLoading(true);
    const lastConversationUpdatedAt = this.conversations[this.conversations.length - 1].updatedAt;
    const pastConversations = await this.chatClient.getConversations(lastConversationUpdatedAt);
    this.setIsMoreConversationsAvailable(pastConversations.isMoreConversationsAvailable);
    this.setConversations([
      ...this.conversations,
      ...pastConversations.conversations
    ]);
    this.setIsPastConversationsLoading(false);
  }
  
  async markAllMessagesAsReadFunc() {
    this.setMarkingAllMessagesAsRead(true);
    await this.chatClient.markAllMessagesAsRead();
    this.setMarkingAllMessagesAsRead(false);
  }
  
  async loadPastMessages() {
    if( this.openedConversation != null ) {
      this.setIsMoreMessagesLoading(true);
      const firstMessageSentAt = this.messagesOfOpenedConversation[0].sentAt;
      const pastMessages = await this.chatClient.getConversationMessages(this.openedConversation?.conversationId, firstMessageSentAt);
      this.setIsMoreMessagesAvailableInOpenedConversation(pastMessages.isMoreMessagesAvailable);
      this.setMessagesOfOpenedConversation([
        ...pastMessages.messages.reverse(),
        ...this.messagesOfOpenedConversation
      ]);
  
      this.setIsMoreMessagesLoading(false);
    }
  }
}

export const chatStore = new ChatStore();
