import React from 'react';
import {Badge, Button, Col, Header, Icon, Input, InputGroup, List, Loader, Message, Row} from 'rsuite';
import Styles from './App.module.scss';
import {HubConnectionState} from '@microsoft/signalr';
import {
  ConversationWithUserMetadata,
  MessageDataWithUserMetadata
} from '@fosh/chat-client/FoshChatClient.Types';
import {IUser} from '../declarations/iUser';
import {observer} from 'mobx-react';
import {chatStore} from '../stores/chatStore';
import {getOtherUserId} from './functions/getOtherUserId';

const App = observer(() => {
  const createConversation = async () => {
    await chatStore.createConversation();
  }
  
  
  const openConversation = async (conversation: ConversationWithUserMetadata<IUser>) => {
    return async () => {
      await chatStore.openConversation(conversation);
    };
  };
  
  const renderConnectionState = () => {
    let color = `#ff0000`;
    let text = 'Disconnected';
    
    switch (chatStore.connectionState) {
      case HubConnectionState.Connecting:
        color = '#af9c04';
        text = 'Connecting...';
        break;
      case HubConnectionState.Connected:
        color = '#5dee00';
        text = 'Connected';
        break;
      case HubConnectionState.Disconnecting:
        color = '#ff860f';
        text = 'Disconnecting...';
        break;
      case HubConnectionState.Reconnecting:
        color = '#cc2af9';
        text = 'Reconnecting...';
        break;
    }
    
    return (
      <Badge content={text} style={{background: color}}/>
    );
  };
  
  const connectToChat = async () => {
    await chatStore.connectToChat();
  }
  
  const disconnectFromChat = async () => {
    await chatStore.disconnectFromChat();
  };
  
  const changeMyUserId = (newUserId: string) => {
    chatStore.setMyUserId(newUserId);
  };
  
  const changeConnectionUrl = (newUrl: string) => {
    chatStore.changeConnectionUrl(newUrl);
  }
  
  const renderConnectionUrlInput = () => {
    return (
      <Input
        placeholder={'Özel Bağlantı (wss://localhost:5001/hubs/chat/ gibi).'}
        className={Styles.ConnectionUrlInput}
        type={'text'}
        onChange={changeConnectionUrl}
        disabled={chatStore.connectionState !== HubConnectionState.Disconnected}
      />
    );
  };
  
  const renderUserIdInput = () => {
    return (
      <Input
        placeholder={'User Id'}
        className={Styles.UserIdInput}
        type={'text'}
        onPressEnter={connectToChat}
        onChange={changeMyUserId}
        disabled={chatStore.connectionState !== HubConnectionState.Disconnected}
      />
    );
  };
  
  const renderConnectionButton = () => {
    let isDisabled = false;
    let text = 'Connect';
    let clickAction = connectToChat;
    
    switch (chatStore.connectionState) {
      case HubConnectionState.Reconnecting:
      case HubConnectionState.Connected:
        isDisabled = false;
        text = 'Disconnect';
        clickAction = disconnectFromChat;
        break;
      case HubConnectionState.Connecting:
      case HubConnectionState.Disconnecting:
        isDisabled = true;
        break;
    }
    
    return (
      <Button
        appearance={'primary'}
        block={true}
        onClick={clickAction}
        disabled={isDisabled}
        className={Styles.ConnectionButton}
      >
        {text}
      </Button>
    );
  };
  
  const loadPastConversations = async () => {
    await chatStore.loadPastConversations();
  };
  
  const markAllMessagesAsReadFunc = async () => {
    await chatStore.markAllMessagesAsReadFunc();
  };
  
  const renderMarkAllConversationsAsReadButton = () => {
    if (chatStore.conversations.length === 0) {
      return null;
    }
    
    return (
      <List.Item>
        <Button
          appearance={'ghost'}
          loading={chatStore.markingAllMessagesAsRead}
          disabled={chatStore.markingAllMessagesAsRead}
          onClick={markAllMessagesAsReadFunc}
        >
          Tüm mesajları okundu olarak işaretle
        </Button>
      </List.Item>
    );
  };
  
  const renderMoreConversationsButton = () => {
    if (!chatStore.isMoreConversationsAvailable) {
      return null;
    }
    
    return (
      <List.Item>
        <Button
          appearance={'subtle'}
          onClick={loadPastConversations}
          loading={chatStore.isPastConversationsLoading}
          disabled={chatStore.isPastConversationsLoading}
        >
          Önceki görüşmeleri yükle
        </Button>
      </List.Item>
    );
  };
  
  const renderUnreadMessagesBadge = (conversation: ConversationWithUserMetadata<IUser>) => {
    if (conversation.unreadMessageCount < 1) {
      return null;
    }
    
    return (
      <Badge content={conversation.unreadMessageCount} style={{backgroundColor: '#b70000', color: '#FFF'}}/>
    );
  };
  
  const renderConversation = (conversation: ConversationWithUserMetadata<IUser>, index: number) => {
    return (
      <List.Item
        key={conversation.conversationId}
        index={index}
      >
        <div onClick={async () => { await (await openConversation(conversation))() }}>
          {getOtherUserId(chatStore.myUserId, conversation.userIds)} - {renderUnreadMessagesBadge(conversation)}
        </div>
      </List.Item>
    );
  };
  
  const renderConversations = () => {
    if (chatStore.isConversationsLoading) {
      return <Loader/>;
    }
    
    if (chatStore.conversations.length === 0) {
      return (
        <Message
          showIcon
          type="info"
          title="Herhangi bir görüşme henüz yok"
          description="Görüşme eklemek için soldan diğer kullanıcının idsini yazıp ekleye basınız."
        />
      );
    }
    
    return (
      <List hover>
        {renderMarkAllConversationsAsReadButton()}
        {chatStore.conversations.map(renderConversation)}
        {renderMoreConversationsButton()}
      </List>
    );
  };
  
  const renderMessage = (messageData: MessageDataWithUserMetadata<IUser>, index: number) => {
    return (
      <List.Item key={messageData.messageId} index={index}>
        {messageData.user?.userId}: {messageData.message} ({messageData.sentAt.toString()})
      </List.Item>
    );
  };
  
  const loadPastMessages = async () => {
    await chatStore.loadPastMessages();
  };
  
  const renderMoreMessagesButton = () => {
    if (!chatStore.isMoreMessagesAvailableInOpenedConversation) {
      return null;
    }
    
    return (
      <List.Item>
        <Button appearance={'subtle'} onClick={loadPastMessages} loading={chatStore.isMoreMessagesLoading}
                disabled={chatStore.isMoreMessagesLoading}>
          Daha eski mesajları yükle
        </Button>
      </List.Item>
    );
  };
  
  const renderMessages = () => {
    if (chatStore.isMessagesLoading) {
      return <Loader/>;
    }
    
    if (chatStore.messagesOfOpenedConversation.length === 0) {
      return (
        <Message
          showIcon
          type="info"
          title="Görüşme içerisinde henüz bir mesaj bulunmuyor"
          description="Aşağıdan ilk mesajınızı göndererek görüşmeye başlayabilirsiniz."
        />
      );
    }
    
    return (
      <List>
        {renderMoreMessagesButton()}
        {chatStore.messagesOfOpenedConversation.map(renderMessage)}
      </List>
    );
  };
  
  const renderOtherUserPresenceBadge = () => {
    let color = '#f80000';
    if (chatStore.otherUserPresenceState) {
      color = '#69d80c';
    }
    
    return <Badge style={{backgroundColor: color}}/>;
  };
  
  const renderMessagesPanelHeader = (conversation: ConversationWithUserMetadata<IUser>) => {
    return (
      <Header>
        {renderOtherUserPresenceBadge()}
        {getOtherUserId(chatStore.myUserId, conversation.userIds)} ile Mesajlaşma
      </Header>
    );
  };
  
  const changeTempMessage = (newText: string) => {
    chatStore.setUserMessage(newText);
  };
  
  const sendMessage = async () => {
    if (chatStore.isSendingMessage || chatStore.openedConversation == null) {
      return;
    }
  
    chatStore.setIsSendingMessage(true);
    await chatStore.chatClient.sendMessage(chatStore.openedConversation.conversationId, chatStore.userMessage);
    chatStore.setIsSendingMessage(false);
  };
  
  const renderMessagingArea = () => {
    if (chatStore.openedConversation == null) {
      return (
        <Message
          showIcon
          type="info"
          title="Bir görüşme seçin yada başlatın"
          description="Mesajları bir görüşme seçerek yada başlatarak görüntüleyebilirsiniz."
        />
      );
    }
    
    return (
      <div className={Styles.MessagesAreaMessages}>
        <div className={Styles.MessagesHeader}>
          {renderMessagesPanelHeader(chatStore.openedConversation)}
        </div>
        <div className={Styles.MessagesArea}>
          {renderMessages()}
        </div>
        <div className={Styles.MessagesInputsArea}>
          <InputGroup inside>
            <Input
              placeholder="Mesajınızı yazın"
              onChange={changeTempMessage}
              onPressEnter={sendMessage}
              disabled={chatStore.isSendingMessage}
              value={chatStore.userMessage}
              errormessage={'Göndermek istediğiniz mesajı yazınız'}
              errorplacement={'topStart'}
            />
            <InputGroup.Addon>
              <Icon icon="send" onClick={sendMessage}/>
            </InputGroup.Addon>
          </InputGroup>
        </div>
      </div>
    );
  };
  
  const changeOtherUserId = (newText: string) => {
    chatStore.setOtherUserId(newText);
  }
  
  const renderOtherUserIdInput = () => {
    return (
      <Input
        type={'text'}
        onChange={changeOtherUserId}
        value={chatStore.otherUserId}
        disabled={chatStore.creatingConversation || chatStore.connectionState !== HubConnectionState.Connected}
        className={Styles.NewConversationInput}
        placeholder={'User idsini giriniz'}
      />
    );
  }
  
  const renderCreateConversationButton = () => {
    return (
      <Button
        disabled={
          chatStore.otherUserId.length === 0 ||
          chatStore.creatingConversation ||
          chatStore.connectionState !== HubConnectionState.Connected
        }
        onClick={createConversation}
        appearance={'primary'}
        block={true}
        loading={chatStore.creatingConversation}
      >
        Create Conversation
      </Button>
    );
  }
  
  return (
    <div className={Styles.App}>
      <Row align={'top'} className={Styles.MainFlex}>
        <Col xs={4}>
          {renderConnectionState()}
          {renderConnectionUrlInput()}
          {renderUserIdInput()}
          {renderConnectionButton()}
          {renderOtherUserIdInput()}
          {renderCreateConversationButton()}
        </Col>
        
        <Col xs={5} xsPush={1}>
          {renderConversations()}
        </Col>
        
        <Col xs={13} xsPush={2}>
          {renderMessagingArea()}
        </Col>
      </Row>
    
    </div>
  );
});

export default App;
