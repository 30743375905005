import {ConversationUserIdWithUserMetadata} from '@fosh/chat-client/FoshChatClient.Types';
import {IUser} from '../../declarations/iUser';

export const getOtherUserId = (myUserId: string, userIds: ConversationUserIdWithUserMetadata<IUser>[]) => {
  const otherUserIds = userIds.filter(f => f.userId !== myUserId);
  
  if (otherUserIds.length > 0) {
    return otherUserIds[0].userId;
  }
}
