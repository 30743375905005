import axios from 'axios';

export const getJwtToken = async (userId: string): Promise<string> => {
  const jwtData = await axios.get('https://chat.fosh.ml/token', {
    params: {
      username: userId
    }
  });
  
  return jwtData.data;
}
