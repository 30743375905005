import {MessageDataWithUserMetadata, MessageRecievedDataWithUserMetadata} from '@fosh/chat-client/FoshChatClient.Types';
import {IUser} from '../../declarations/iUser';

export const convertReceivedMsgToMsgWithUser = (data: MessageRecievedDataWithUserMetadata<IUser>): MessageDataWithUserMetadata<IUser> => {
  return {
    user: data.user,
    message: data.message,
    messageId: data.messageId,
    sentAt: data.messageTime,
    senderUserId: data.senderId
  };
};
